import React, { useState, useEffect } from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import images from './images';



function ImageGrid() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const imageArray = Array.isArray(images) ? images : [];

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  const handlePrevImage = () => {
    const index = imageArray.indexOf(selectedImage);
    setSelectedImage(imageArray[(index - 1 + imageArray.length) % imageArray.length]);
  };

  const handleNextImage = () => {
    const index = imageArray.indexOf(selectedImage);
    setSelectedImage(imageArray[(index + 1) % imageArray.length]);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handlePrevImage();
    } else if (event.key === 'ArrowRight') {
      handleNextImage();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  });

  console.log(imageArray);
  console.log('Pub URL Test' + process.env.REACT_APP_PUBLIC_URL);

  return (
    <div className="App">
      <header className="App-header">
        <Container className="Box">
          <h2>A few Memories</h2>
          <p>(I'll add pics here soon)</p>
          <div className="image-grid">
              {imageArray.map((image) => (
                <div key={image.id} className="image-item">
                    <img
                        src={process.env.REACT_APP_PUBLIC_URL + image.url}
                        alt={image.alt}
                        title={image.alt}
                        onClick={() => handleImageClick(image)}
                    />
                </div>
            ))}
          </div> <br></br>
          <div>
            <p> If you made it this far and still want more info, just drop me a line: <a href="mailto:hello@marcjohnson.xyz" className='App-link'>hello@marcjohnson.xyz</a></p>
          </div>
          <div className="credits">
            <Link to="/">
              <button className="button">Home</button>
            </Link>
          </div>
        </Container>
      </header>
      {selectedImage && (
        <Modal show={showModal} onHide={handleCloseModal} size='lg' className='Modal'>
          <Modal.Header className="btn-close-white" closeButton>
          <div className="header-text">
             {selectedImage.location}, {selectedImage.date}
          </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-container">
              <div className="modal-prev-arrow" onClick={handlePrevImage}>
                {'<'}
              </div>
              <img
                src={selectedImage.url}
                alt={selectedImage.alt}
                title={selectedImage.title}
              />
              <div className="modal-next-arrow" onClick={handleNextImage}>
                {'>'}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer"> 
              {selectedImage.alt}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default ImageGrid;

